import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "react-bootstrap";

export const PageContainer: React.FC<ContainerProps> = (props) => (
  <Container
    {...props}
    className={clsx(props.className, "mx-0 px-0")}
    fluid="md"
  />
);
