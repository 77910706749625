import { LucideIcon } from "lucide-react";
import React from "react";
import { Dropdown, DropdownItemProps, Stack } from "react-bootstrap";
import { ButtonVariant, Color } from "react-bootstrap/esm/types";
import { Button, ButtonProps } from "./Button";
import { DisabledTooltip } from "./DisabledTooltip";
import { Loading } from "./Loading";
import { Icon } from "./icons/Icon";

export type FlexibleActionType = "button" | "dropdown_item" | "link";

type SharedFlexibleActionProps = {
  type?: FlexibleActionType;
  icon?: LucideIcon;
  iconColor?: Color;
  disabled?: boolean;
  disabledTooltip?: string;
  href?: string;
  className?: string;
  loading?: boolean;
  block?: boolean;
  onClick?: React.MouseEventHandler;
};

type ButtonFlexibleActionProps = SharedFlexibleActionProps & {
  type: "button";
  variant?: ButtonVariant;
} & Omit<ButtonProps, "as">;

type DropdownItemFlexibleActionProps = SharedFlexibleActionProps & {
  type: "dropdown_item";
} & Omit<DropdownItemProps, "as">;

type LinkFlexibleActionProps = SharedFlexibleActionProps & {
  type: "link";
} & React.ComponentPropsWithoutRef<"a">;

export type FlexibleActionProps =
  | ButtonFlexibleActionProps
  | DropdownItemFlexibleActionProps
  | LinkFlexibleActionProps;

const FlexibleDropdownItem: React.FC<
  Omit<DropdownItemFlexibleActionProps, "type">
> = ({
  disabled,
  disabledTooltip,
  loading,
  icon,
  iconColor,
  children,
  block,
  ...rest
}) => {
  return (
    <Dropdown.Item disabled={disabled || loading} {...rest}>
      <DisabledTooltip
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        block={block}
      >
        <Stack direction="horizontal" className="align-items-center" gap={2}>
          {loading ? (
            <Loading />
          ) : (
            <>{icon && <Icon color={iconColor} icon={icon} />}</>
          )}

          <span>{children}</span>
        </Stack>
      </DisabledTooltip>
    </Dropdown.Item>
  );
};

const FlexibleLink: React.FC<Omit<LinkFlexibleActionProps, "type">> = ({
  disabled,
  disabledTooltip,
  loading,
  icon,
  iconColor,
  children,
  block,
  ...rest
}) => {
  return (
    <a {...rest}>
      {loading ? (
        <Loading size="sm" />
      ) : (
        <>{icon && <Icon color={iconColor} icon={icon} className="me-1" />}</>
      )}
      <DisabledTooltip
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        block={block}
      >
        <span>{children}</span>
      </DisabledTooltip>
    </a>
  );
};

export const FlexibleAction: React.FC<FlexibleActionProps> = ({
  type,
  icon,
  ...props
}) => {
  if (type === "button") {
    return (
      <Button StartIcon={icon} {...(props as ButtonFlexibleActionProps)} />
    );
  } else if (type === "dropdown_item") {
    return <FlexibleDropdownItem icon={icon} {...props} />;
  } else if (type === "link") {
    return <FlexibleLink {...(props as LinkFlexibleActionProps)} />;
  }
};
