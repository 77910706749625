import * as Lucide from "lucide-react";

export const InsertIcon = Lucide.createLucideIcon("Insert", [
  ["path", { d: "M20 16L16 12L20 8", key: "a" }],
  ["path", { d: "M5 12L11 12", key: "b" }],
  ["path", { d: "M5 18L15 18", key: "c" }],
  ["path", { d: "M5 6L15 6", key: "d" }],
]);

export { InsertIcon as default };
