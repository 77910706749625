import md5 from "md5";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { toast } from "react-toastify";

export type FlashMessages = {
  info: string[];
  error: string[];
  warning: string[];
  success: string[];
};

export type FlashMessageType = keyof FlashMessages;

const destroyFlashCookie = () => destroyCookie(null, "flash", { path: "/" });

const getFlashMessages = (): FlashMessages => {
  const { flash } = parseCookies();

  if (flash) {
    try {
      const { info, error, warning, success } = JSON.parse(flash) as any;

      return { info, error, warning, success };
    } catch (err) {
      console.warn("Unparseable flash message received", flash);
    } finally {
      // We got the cookie and we're going to display it, so we
      // need to clear the cookie out
      destroyFlashCookie();
    }
  }

  return { info: [], error: [], warning: [], success: [] };
};

export const clearFlashMessages = () => {
  toast.dismiss();
  destroyFlashCookie();
};

export const setFlashMessage = (message: string, type: FlashMessageType) => {
  const existingMessages = getFlashMessages();

  existingMessages[type].push(message);

  setCookie(null, "flash", JSON.stringify(existingMessages), { path: "/" });
};

export const FlashMessagesContainer = () => {
  const { info, error, warning, success } = getFlashMessages();

  info.forEach((i) =>
    toast.info(decodeURIComponent(i), { toastId: md5(i), autoClose: 6000 }),
  );

  error.forEach((e) =>
    toast.error(decodeURIComponent(e), { toastId: md5(e), autoClose: 6000 }),
  );

  warning.forEach((w) =>
    toast.warn(decodeURIComponent(w), { toastId: md5(w), autoClose: 6000 }),
  );

  success.forEach((s) =>
    toast.success(decodeURIComponent(s), { toastId: md5(s), autoClose: 6000 }),
  );

  return null;
};
