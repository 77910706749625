import React from "react";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import { ModalBodyProps } from "react-bootstrap/esm/ModalBody";

export const TwoColumnModalBody: React.FC<
  ModalBodyProps & { sidebar?: React.ReactNode; gap?: number; sidebarWidth?: 4 }
> = ({ children, sidebar, gap = 3, sidebarWidth = 4, ...rest }) => {
  return (
    <Modal.Body {...rest}>
      <Row>
        <Col md={sidebar ? 12 - sidebarWidth : 12}>
          <Stack gap={gap}>{children}</Stack>
        </Col>
        {sidebar && (
          <Col md={sidebarWidth}>
            <Stack as="aside" gap={gap}>
              {sidebar}
            </Stack>
          </Col>
        )}
      </Row>
    </Modal.Body>
  );
};
