import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useMeasureWidth } from "../hooks/useMeasureWidth";

export type ResponsiveButtonStackProps = {
  gap?: number;
  breakpoint?: number;
  className?: string;
} & PropsWithChildren;

export const ResponsiveButtonStack: React.FC<ResponsiveButtonStackProps> = ({
  children,
  gap = 2,
  breakpoint = 320,
  className,
}) => {
  const { ref, width } = useMeasureWidth<HTMLDivElement>();

  return (
    <div className="w-100" ref={ref}>
      <div
        className={clsx(
          "button_stack",
          className,
          `gap-${gap}`,
          width && width < breakpoint ? "d-grid" : "d-inline-flex",
        )}
      >
        {children}
      </div>
    </div>
  );
};
