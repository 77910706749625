import { LucideIcon } from "lucide-react";
import React from "react";
import { Card, CardProps } from "react-bootstrap";
import { Toolbar } from "../Toolbar";
import { IconText } from "./IconText";

export type IconCardProps = {
  title: React.ReactNode;
  icon?: LucideIcon;
  skipBody?: boolean;
  controls?: React.ReactNode;
  footer?: React.ReactNode;
} & Omit<CardProps, "title">;

export const IconCard: React.FC<IconCardProps> = ({
  title,
  icon,
  children,
  skipBody = false,
  controls,
  footer,
  ...rest
}) => {
  const BodyComp = skipBody ? React.Fragment : Card.Body;

  return (
    <Card {...rest}>
      <Card.Header>
        <Toolbar className="align-items-center">
          <IconText icon={icon}>{title}</IconText>

          {controls && <div className="ms-auto">{controls}</div>}
        </Toolbar>
      </Card.Header>

      <BodyComp>{children}</BodyComp>

      {footer}
    </Card>
  );
};
