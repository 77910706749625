import { Icon } from "@ream/ui";
import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React from "react";
import { Color } from "../types";

export const ActionCard: React.FC<{
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  icon?: LucideIcon;
  color?: Color;
  style?: React.CSSProperties;
  onClick?: () => void;
}> = ({ title, subtitle, children, color, style, onClick, icon }) => {
  return (
    <div
      className={clsx(
        "action_card d-flex flex-column border rounded",
        `border-${color}-subtle`,
        onClick && "action_card--action",
      )}
      onClick={onClick}
      style={style}
    >
      <div className="p-2 d-flex flex-column gap-2" style={{ flex: 1 }}>
        {icon && (
          <Icon
            icon={icon}
            color={color}
            className="action_card__icon align-self-start"
            size="lg"
          />
        )}

        <span
          className={`action_card__title text-${color}-800 stretched-link fw-bold text-decoration-none max-lines-2`}
        >
          {title}
        </span>

        {subtitle && (
          <span className="action_card__subtitle mt-auto text-muted small">
            {subtitle}
          </span>
        )}

        {children}
      </div>

      <div
        className={`action_card__footer bg-${color}-50`}
        style={{ height: 16 }}
      ></div>
    </div>
  );
};
