import React from "react";

/* eslint-disable react-hooks/exhaustive-deps */
// taken from https://github.com/rauldeheer/use-async-effect/blob/master/index.js
export function useAsyncEffect<V>(
  effect: (_isActive: () => boolean) => V | Promise<V>,
  inputs?: React.DependencyList,
  destroy?: (_result?: V) => void,
): void {
  React.useEffect(function () {
    var result;
    var mounted = true;

    var maybePromise = effect(function () {
      return mounted;
    });

    Promise.resolve(maybePromise).then(function (value) {
      result = value;
    });

    return function () {
      mounted = false;

      if (typeof destroy === "function") {
        destroy(result);
      }
    };
  }, inputs);
}
/* eslint-enable react-hooks/exhaustive-deps */
