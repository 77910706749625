import clsx from "clsx";
import React, { HTMLProps, PropsWithChildren } from "react";

export const OverlapStack: React.FC<
  PropsWithChildren & HTMLProps<HTMLDivElement>
> = ({ children, className, ...rest }) => (
  <div className={clsx("overlap_stack", className)} {...rest}>
    {children}
  </div>
);
