import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const DomPortal: React.FC<{ children: React.ReactNode; id: string }> = ({
  children,
  id,
}) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(id);
    setMounted(true);
  }, [id]);

  return mounted && ref.current
    ? createPortal(<>{children}</>, ref.current)
    : null;
};
