import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import hash from "object-hash";
import qs from "qs";
import { useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import { clearToken, getToken } from "../util/auth";
import { AppRoutes } from "./appRoutes";
import { redirect } from "./redirect";
import { parameterizeUrl } from "./routing";

const CancelToken = axios.CancelToken;

// Default to empty string on `production` environments because process.env is in-accessible at docker run time.
// In order to pass through to the server must specify the path on the ALB configuration
const API_URL =
  (process.browser
    ? process.env.NEXT_PUBLIC_CLIENT_API_URL
    : process.env.NEXT_PUBLIC_API_URL) ?? "";

// Defaults to the requesting host on `production` environments because process.env is in-accessible at docker run time.
export const SYNC_SERVICE_URL =
  process.env.NEXT_PUBLIC_CLIENT_SYNC_SERVICE_URL ??
  (process.browser ? `wss://${window.location.host}:1234` : "");

export const apiUrl = (path: string) => `${API_URL}${path}`;

export const tokenizedUrl = (path: string) =>
  parameterizeUrl(path, { token: getToken() });

export const tokenizedApiUrl = (path: string) => tokenizedUrl(apiUrl(path));

export const syncServiceUrl = (path: string) => `${SYNC_SERVICE_URL}${path}`;

const stringifyParams = (params: Record<string, any>): string =>
  qs.stringify(params, {
    arrayFormat: "brackets",
  });

export const api = axios.create({
  baseURL: API_URL,
  timeout: 20000,
  paramsSerializer: {
    serialize: stringifyParams,
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      if (config.headers) {
        config.headers.authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  // based on: https://brandoncc.medium.com/how-to-handle-unhandledrejection-errors-using-axios-da82b54c6356
  (error) => {
    throw error;
  },
);

api.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status == 401) {
    if (error?.config?.url !== "/api/login") {
      clearToken();
      const serverError = error.response.data?.errors?.[0];
      const expired = Boolean(error.response.data?.extras?.expired);
      const user = error.response.data?.extras?.user;
      const token = error.response.data?.extras?.token;
      const redirectMessage = serverError ?? "You have been logged out";

      const loginParams = new URLSearchParams();
      if (expired) {
        loginParams.set("expired_token", "true");
      }
      if (user) {
        loginParams.set("email", user);
      }
      if (token) {
        loginParams.set("token", token);
      }
      const baseUrl =
        expired && token && user ? AppRoutes.expiredToken() : AppRoutes.login();
      const params = loginParams.toString();
      const loginUrl = params ? `${baseUrl}?${params}` : baseUrl;

      redirect(loginUrl, redirectMessage, "warning");
    }
  }

  throw error;
});

export const getBase64 = async (url: string): Promise<string> => {
  const res = await api.get(url, {
    responseType: "arraybuffer",
  });

  return Buffer.from(res.data, "binary").toString("base64");
};

type Extension = ".pdf" | ".csv" | ".doc" | ".docx" | ".txt" | null;
export const parseFilenameFromResponse = (
  resp: AxiosResponse,
  ext?: Extension,
): string | null => {
  const headers = resp.headers["content-disposition"];
  if (headers) {
    const splitHeaders = headers.split("filename=");
    if (length == 0) {
      return null;
    } else if (length == 1) {
      return splitHeaders[0];
    }
    const splitBySemi = splitHeaders[1].split(";");
    if (splitBySemi.length == 0) {
      return null;
    }
    if (ext) {
      const splitOffExtension = splitBySemi[0].split(ext);
      return `${splitOffExtension}${ext}`;
    }
    return splitBySemi[0];
  }
  return null;
};

export const fileFromResponse = (
  resp: AxiosResponse,
  defaultName: string,
  ext?: Extension,
) => {
  const filename = parseFilenameFromResponse(resp, ext);
  return new File([resp.data], filename ?? defaultName);
};

export const useAPI = <T>(
  url: string,
  config: AxiosRequestConfig = {},
  initialFetch: boolean = true,
) => {
  const [error, setError] = useImmer<AxiosError | null | undefined>(undefined);
  const [isLoading, setIsLoading] = useImmer<boolean>(false);
  const [response, setResponse] = useImmer<AxiosResponse<T> | null | undefined>(
    undefined,
  );

  // Create cancel token
  const source = CancelToken.source();
  const configHash = hash(config);

  // Define fetch method
  const fetch = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api(url, { ...config, cancelToken: source.token });
      setResponse(response);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(`Request cancelled by cleanup: ${error.message}`);
      } else {
        setError(error as AxiosError);
      }
    } finally {
      setIsLoading(false);
    }
  }, [url, configHash]);

  // Apply effect
  useEffect(() => {
    if (initialFetch) {
      fetch();
    }

    return () => {
      source.cancel("useEffect cleanup");
    };
  }, [url, initialFetch, configHash]);

  // Return values
  const setData = (newData) => {
    const newResponse: AxiosResponse<T> = {
      ...response,
      data: newData,
    } as AxiosResponse<T>;

    setResponse(newResponse);
  };

  // Return either the response data or nothing
  const data = response ? response.data : undefined;
  return { data, response, error, isLoading, setData, fetch };
};
