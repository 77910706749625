import React, { useCallback, useState } from "react";
import { FormControl, FormGroup, Modal } from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";
import { Button } from "../Button";

type BaseUseConfirmationDialogProps = {
  title: React.ReactNode;
  message: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  variant?: Color;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
};

type UsePromptDialogProps = BaseUseConfirmationDialogProps & {
  prompt: true;
  onConfirm: (userInput: string) => void | Promise<void>;
  defaultValue?: string;
};

type UseConfirmationDialogWithArgsProps<T> = BaseUseConfirmationDialogProps & {
  prompt?: false;
  onConfirm: (args?: T) => void | Promise<void>;
  defaultValue?: undefined;
};

type UseConfirmationDialogProps<T> =
  | UsePromptDialogProps
  | UseConfirmationDialogWithArgsProps<T>;

export const useConfirmationDialog = <T,>({
  title,
  message,
  size,
  onConfirm,
  prompt = false,
  variant = "primary",
  confirmText = "Ok",
  cancelText = "Cancel",
  defaultValue,
}: UseConfirmationDialogProps<T>): [
  JSX.Element,
  (args?: T) => void,
  boolean,
] => {
  const [open, setOpen] = useState(false);
  const [args, setArgs] = useState<undefined | T>(undefined);
  const [confirming, setConfirming] = useState(false);

  const openDialog = (args?: T) => {
    setOpen(true);
    setArgs(args);
  };

  const closeDialog = () => {
    setOpen(false);
    setArgs(undefined);
  };

  const dialog = (
    <ConfirmationDialog
      title={title}
      message={message}
      open={open}
      size={size}
      onClose={closeDialog}
      variant={variant}
      confirmText={confirmText}
      cancelText={cancelText}
      defaultValue={defaultValue}
      onConfirm={async (input?: string) => {
        setConfirming(true);

        try {
          if (prompt) {
            await onConfirm(input ?? ("" as any));
          } else {
            await onConfirm(args as any);
          }
        } finally {
          setConfirming(false);
        }
      }}
      // typescript throws a fit about this for some reason
      // when it's not an any
      prompt={prompt as any}
    />
  );

  return [dialog, openDialog, confirming];
};

export const usePromptDialog = (props: UsePromptDialogProps) =>
  useConfirmationDialog({ ...props, prompt: true });

type Props = {
  open: boolean;
  title?: React.ReactNode;
  message: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  onClose: () => void | Promise<void>;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
  variant?: Color;
};

type ConfirmationDialogProps = {
  prompt?: false;
  defaultValue?: undefined;
  onConfirm: () => void | Promise<void>;
} & Props;

type PromptDialogProps = {
  prompt: true;
  defaultValue?: string;
  onConfirm: (userInput: string) => void | Promise<void>;
} & Props;

export const ConfirmationDialog: React.FC<
  ConfirmationDialogProps | PromptDialogProps
> = ({
  open,
  title,
  message,
  size,
  onClose,
  onConfirm,
  prompt,
  defaultValue,
  confirmText = "Ok",
  cancelText = "Cancel",
  variant = "primary",
}) => {
  const [input, setInput] = useState<string>(defaultValue ?? "");

  const internalOnConfirm = useCallback(() => {
    if (prompt) {
      onConfirm(input);
    } else {
      onConfirm();
    }

    onClose();
  }, [prompt, onConfirm, onClose]);

  return (
    <Modal show={open} onHide={onClose} size={size}>
      {title && <Modal.Header>{title}</Modal.Header>}

      <Modal.Body>
        {message}
        {prompt && (
          <FormGroup>
            <FormControl
              type="text"
              value={input ?? ""}
              onChange={(e) => setInput(e.target.value ?? "")}
            />
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">
          {cancelText}
        </Button>

        <Button
          onClick={internalOnConfirm}
          disabled={prompt && !input}
          variant={variant}
          autoFocus
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
