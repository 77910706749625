export const AUTH_STORAGE_NAME = "__ream_auth";

export const setToken = (token: string): void => {
  if (typeof window !== "undefined") {
    localStorage.setItem(AUTH_STORAGE_NAME, token);
  }
};

export const getToken = (): string | null =>
  typeof window !== "undefined"
    ? localStorage.getItem(AUTH_STORAGE_NAME)
    : null;

export const clearToken = (): void => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(AUTH_STORAGE_NAME);
  }
};
