import React from "react";
import { Popover } from "react-bootstrap";
import { BaseOverlay, BaseOverlayProps } from "./BaseOverlay";

export const BasePopover: React.FC<
  BaseOverlayProps & { skipBody?: boolean; title?: React.ReactNode }
> = ({ skipBody = false, children, title, ...rest }) => {
  const BodyComp = skipBody ? React.Fragment : Popover.Body;

  return (
    <BaseOverlay {...rest} as={Popover}>
      {({ handleClose }) => (
        <>
          {title && <Popover.Header>{title}</Popover.Header>}

          <BodyComp>{children({ handleClose })}</BodyComp>
        </>
      )}
    </BaseOverlay>
  );
};
