import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React, { HTMLProps } from "react";
import { Color } from "react-bootstrap/esm/types";
import { Icon, IconProps } from "./Icon";

export type IconCircleProps = HTMLProps<HTMLDivElement> & {
  size?: number;
  color?: Color;
  rounded?: "circle" | "rounded";
  flat?: boolean;
  icon: LucideIcon;
  iconProps?: Omit<IconProps, "name">;
};

export const IconCircle = React.forwardRef<HTMLDivElement, IconCircleProps>(
  (
    {
      size = 28,
      color = "light",
      icon,
      iconProps,
      flat = false,
      rounded = "circle",
      ...rest
    },
    ref,
  ) => {
    const iconSize = size < 32 ? "sm" : size >= 48 ? "lg" : undefined;

    return (
      <div
        ref={ref}
        className={clsx(
          `bg-${color} icon_circle border border-${color}-subtle d-flex align-items-center justify-content-center`,
          rest.onClick && "icon_circle--actionable",
          !flat && "shadow-sm",
          rounded === "circle" ? "rounded-circle" : "rounded",
        )}
        style={{ width: size, height: size }}
        {...rest}
      >
        <Icon icon={icon} {...iconProps} color="white" size={iconSize} />
      </div>
    );
  },
);

IconCircle.displayName = "IconCircle";
