import React, { HTMLProps } from "react";
import {
  ZonedDateTimeString,
  formatDate,
  formatDateIso,
  formatDateTime,
  formatSince,
} from "../utils/dates";

type AbsoluteTimeProps = {
  absolute: true;
  format?: string;
  addSuffix?: undefined;
};

type RelativeTimeProps = {
  absolute?: false;
  addSuffix?: boolean;
  format?: undefined;
};

type TimeProps = HTMLProps<HTMLTimeElement> & {
  date: Date | ZonedDateTimeString;
} & (AbsoluteTimeProps | RelativeTimeProps);

export const Time: React.FC<TimeProps> = ({
  date,
  addSuffix = false,
  absolute = false,
  format,
  ...rest
}) => (
  <time dateTime={formatDateIso(date)} title={formatDateTime(date)} {...rest}>
    {absolute ? formatDate(date, format) : formatSince(date, { addSuffix })}
  </time>
);
