import { isFunction, noop } from "lodash-es";
import { useEffect, useState } from "react";
import { safeJSONParse } from "../utils/safeJSONParse";

export const useStateParams = <T,>(
  paramsName: string,
  defaultState: T,
  history: "push" | "replace" = "push",
): [T, (state: T) => void] => {
  if (!window) {
    return [defaultState, noop];
  }

  const search = new URLSearchParams(window.location.search);

  const existingValue = search.get(paramsName);
  const [state, setState] = useState(
    existingValue ? safeJSONParse(existingValue) : defaultState,
  );

  // Updates state when user navigates backwards or forwards in browser history
  useEffect(() => {
    if (existingValue) {
      // react will auto-bail if this is already the value
      // in the state object, so no need to do any fancy
      // conditional checking here.
      setState(safeJSONParse(existingValue));
    }
  }, [existingValue]);

  const onChange = (s) => {
    const newState = isFunction(s) ? s(state) : s;
    const searchParams = new URLSearchParams(window.location.search);
    const serializedState = JSON.stringify(newState);

    // Set state for react
    setState(newState);

    if (serializedState && newState !== defaultState) {
      searchParams.set(paramsName, serializedState);
    } else {
      searchParams.delete(paramsName);
    }

    // Serialize state to URL
    const queryString = searchParams.toString();
    const path = queryString
      ? `${window.location.pathname}?${queryString}`
      : window.location.pathname;

    if (history === "push") {
      window.history.pushState(null, "", path);
    } else {
      window.history.replaceState(null, "", path);
    }
  };

  return [state, onChange];
};
