import clsx from "clsx";
import React from "react";

export const Toolbar: React.FC<{
  children: React.ReactNode;
  justify?: "start" | "end" | "center" | "between" | "around";
  gap?: number;
  fullWidth?: boolean;
  className?: string;
}> = ({
  justify = "between",
  gap,
  fullWidth: fw = true,
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        "d-flex flex-row align-items-center",
        `justify-content-${justify}`,
        gap && `gap-${gap}`,
        fw && "w-100",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
