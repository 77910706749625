import clsx from "clsx";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type DisabledTooltipProps = {
  disabled?: boolean;
  block?: boolean;
  tooltip?: string;
  disabledTooltip?: string;
  children: React.ReactNode;
};
export const DisabledTooltip: React.FC<DisabledTooltipProps> = ({
  disabled,
  disabledTooltip,
  tooltip,
  children,
  block,
}) => {
  const tooltipText = disabled ? disabledTooltip : tooltip;
  const hasTooltip = Boolean(tooltipText);
  if (hasTooltip) {
    const renderTooltip = (props) => (
      <Tooltip {...props}>{tooltipText}</Tooltip>
    );
    return (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <span
          className={clsx({ "d-inline-block": !block, "d-block": block })}
          tabIndex={0}
        >
          {children}
        </span>
      </OverlayTrigger>
    );
  } else {
    return <>{children}</>;
  }
};
