import { forEach, transform } from "lodash-es";
import QueryString from "qs";

export type RouteCollection = Record<string, string>;
export type RouteHelpers<T> = Record<keyof T, (_d?: object) => string>;

export const parameterizeUrl = (url: string, params: object = {}) => {
  const query = QueryString.stringify(params);
  const delim = url.includes("?") ? "&" : "?";

  return query ? `${url}${delim}${query}` : url;
};

const makeRouteFn = (route: string) => {
  return (data: object = {}) => {
    let queryData = {};
    let finalRoute = route;

    forEach(data, (value, key) => {
      let repl = `:${key}`;

      if (route.includes(repl)) {
        finalRoute = finalRoute.replaceAll(repl, value);
      } else {
        queryData[key] = value;
      }
    });

    return parameterizeUrl(finalRoute, queryData);
  };
};

export const templatizeRouteObj = <T extends RouteCollection>(
  obj: T,
): RouteHelpers<T> => {
  return transform(
    obj,
    (acc, route, key) => {
      acc[key as keyof T] = makeRouteFn(route);
    },
    {} as RouteHelpers<T>,
  );
};
