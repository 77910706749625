import { templatizeRouteObj } from "./routing";

const routes = {
  legalElectronicSignatureConsent: "/legal/electronic-signature-consent",
  legalSigningRequirements: "/legal/signing-system-requirements",

  landing: "/",
  root: "/",
  login: "/login",
  signup: "/signup",
  changeLogin: "/change-login",
  expiredToken: "/expired-token",
  forgotPassword: "/forgot-password",

  quarantine: "/quarantine",

  campaigns: "/campaigns",
  campaign: "/campaigns/:id",
  createCampaign: "/campaigns/create",

  documents: "/",
  document: "/documents/:id",
  documentEditor: "/documents/:id/edit",
  executePacket: "/documents/execute",
  templates: "/templates",

  reviews: "/reviews",
  review: "/reviews/:id",
  createReview: "/reviews/create",

  negotiations: "/negotiations",
  negotiation: "/negotiations/:id",
  createNegotiation: "/negotiations/create",

  signatures: "/signatures",
  signature: "/signatures/:id",
  createSignature: "/signatures/create",

  contacts: "/contacts",
  contact: "/contacts/:id",
  companies: "/companies",
  company: "/companies/:id",

  orgSettings: "/settings/org",
  profileSettings: "/settings/profile",
};

export const AppRoutes = templatizeRouteObj(routes);
