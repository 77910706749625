import { LoadingCentered } from "@ream/ui";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { decode } from "js-base64";
import { has, isString, noop } from "lodash-es";
import { usePathname } from "next/navigation";
import { useQueryState } from "nuqs";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { clearFlashMessages } from "src/components/FlashMessagesContainer";
import { Account } from "src/types";
import { extractErrorMessage } from "src/util/api/apiError";
import {
  useCreateCompany,
  useCreateSession,
  useCreateSessionWithToken,
  useImpersonateUser,
  validateMagicToken,
  validateSession,
} from "src/util/api/authApi";
import { useCurrentUser } from "src/util/api/usersApi";
import { AppRoutes } from "src/util/appRoutes";
import { asyncNoop } from "src/util/noop";
import { isRestrictedAccount, isRestrictedOrg } from "src/util/permissions";
import { useRedirect } from "src/util/redirect";
import { clearToken, getToken, setToken } from "../util/auth";
import { useAsyncEffect } from "../util/hooks/useAsyncEffect";

type AuthContextData = {
  user?: Account;
  trueUser?: Account;
  authToken: any;
  isAuthenticated: boolean;
  hasAuthenticated: boolean;
  isImpersonating: boolean;
  loading: boolean;
  processAuthorizationHeader: (_authResponseHeader: string | undefined) => void;
  startImpersonatingUser: () => Promise<void>;
  stopImpersonatingUser: () => Promise<void>;
  login: (_email: string, _password: string) => Promise<void>;
  loginWithToken: (_email: string) => Promise<void>;
  logout: (args?: { next?: string }) => void;
  register: (name: string, email: string, companyName: string) => Promise<void>;
};

const AuthContext = React.createContext<AuthContextData>({
  hasAuthenticated: false,
  isAuthenticated: false,
  isImpersonating: false,
  loading: false,
  authToken: null,
  processAuthorizationHeader: noop,
  startImpersonatingUser: asyncNoop,
  stopImpersonatingUser: asyncNoop,
  login: asyncNoop,
  loginWithToken: asyncNoop,
  logout: noop,
  register: asyncNoop,
});

export const useAuth = () => useContext(AuthContext);

export const useSelfId = () => {
  const { user } = useAuth();
  return user?.publicUid;
};

export const useAuthGate = () => {
  const [authGateChecked, setAuthGateChecked] = useState(false);

  const { user, isAuthenticated, logout, loginWithToken, hasAuthenticated } =
    useAuth();

  const pathname = usePathname();
  const redirect = useRedirect();

  const { magicTokenParam, clearMagicTokenParam } = useMagicTokenParam();

  const handleMagicToken = useCallback(
    async (token: string) => {
      if (isAuthenticated && user?.email) {
        // a magic token is present, but the user is already authenticated
        // we'll check to see if it's for the same user. If it is, we'll just strip the token
        // if it's for a different user, we'll redirect to the change-login page
        try {
          const result = await validateMagicToken({ token });

          if (result.email === user.email) {
            clearMagicTokenParam();
          } else {
            const next = window.location.pathname + window.location.search;
            const nextParam = next === "/" ? undefined : next;
            if (result.expired) {
              redirect(
                AppRoutes.expiredToken({
                  token,
                  magic_token: null,
                  next: nextParam,
                  email: result.email,
                }),
              );
            } else {
              redirect(
                AppRoutes.changeLogin({
                  token,
                  magic_token: null,
                  next: nextParam,
                  token_email: result.email,
                }),
              );
            }
          }
        } catch (err) {
          // what should we do when there's a magic token error?
          toast.warn(`You are already logged in as ${user.email}.`);
          redirect(AppRoutes.root());
        }
      } else {
        console.log("Logging in with token!");
        // a magic token is present and the user is not already
        // authenticated, so try to log in with it
        await loginWithToken(token);
        clearMagicTokenParam();
      }
    },
    [
      isAuthenticated,
      user?.email,
      clearMagicTokenParam,
      redirect,
      loginWithToken,
    ],
  );

  const handleAuthGateRedirects = useCallback(() => {
    // We don't have a magic token to deal with, so we're going to do
    // a standard auth check.

    if (!isAuthenticated) {
      // If we are not authenticated, then logout and set the next
      // url to whatever page the user is currently on.
      const next = window.location.pathname + window.location.search;
      logout({ next: next === "/" ? undefined : next });
    } else {
      // If we are authenticated, check that the user is not restricted
      // if they are, then redirect the user to the quarantine page.
      const isRestricted =
        user &&
        user.role !== "contact" &&
        (isRestrictedAccount(user) || isRestrictedOrg(user.org));

      if (isRestricted && pathname !== AppRoutes.quarantine()) {
        redirect(AppRoutes.quarantine());
      }
    }
  }, [isAuthenticated, logout, user, pathname, redirect]);

  useAsyncEffect(
    async (isActive) => {
      if (!isActive() || !hasAuthenticated || authGateChecked) {
        return;
      }

      if (magicTokenParam && isString(magicTokenParam)) {
        await handleMagicToken(magicTokenParam);
      } else {
        handleAuthGateRedirects();
      }

      if (isActive() && !authGateChecked) {
        setAuthGateChecked(true);
      }
    },
    [
      magicTokenParam,
      authGateChecked,
      hasAuthenticated,
      handleAuthGateRedirects,
      handleMagicToken,
    ],
  );

  return authGateChecked;
};

const useMagicTokenParam = () => {
  const [magicTokenParam, setMagicToken] = useQueryState("magic_token", {
    clearOnDefault: true,
  });

  const clearMagicTokenParam = useCallback(() => {
    setMagicToken(null);
  }, [setMagicToken]);

  return { magicTokenParam, clearMagicTokenParam };
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(decode(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const isAuthError = (error: Error | AxiosError): boolean =>
  axios.isAxiosError(error) &&
  [403, 401].includes(error?.response?.status ?? 0);

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const redirect = useRedirect();
  const queryClient = useQueryClient();
  const { mutateAsync: registerCompany } = useCreateCompany();
  const { mutateAsync: createSession } = useCreateSession();
  const { impersonate, stopImpersonating } = useImpersonateUser();
  const { mutateAsync: createSessionWithToken } = useCreateSessionWithToken();
  const {
    data: { account: currentUser, meta } = { account: undefined },
    query: { refetch: refetchUser, isFetching: isLoadingUser },
  } = useCurrentUser();

  const trueUser: Account | undefined = meta?.trueAccount;

  const [authToken, setAuthToken] = useState<undefined | string | null>(
    undefined,
  );

  const hasAuthenticated = typeof authToken !== "undefined";
  const isAuthenticated = Boolean(authToken);

  const isImpersonating = useMemo(() => {
    if (!isAuthenticated || !authToken) {
      return false;
    }

    const decoded = parseJwt(authToken);

    if (!decoded) {
      return false;
    }

    return has(decoded, "imp");
  }, [isAuthenticated, authToken]);

  const processAuthorizationHeader = useCallback(
    async (header: string | undefined) => {
      if (header) {
        const token = header.substring(7, header.length);
        setAuthToken(token);
        setToken(token);
        await refetchUser();
      }
    },
    [refetchUser],
  );

  const login = useCallback(
    async (email: string, password: string) => {
      clearFlashMessages();
      await createSession(
        { email, password },
        {
          onSuccess: async (res) => {
            await processAuthorizationHeader(res.headers.authorization);
          },
        },
      );
    },
    [createSession, processAuthorizationHeader],
  );

  const loginWithToken = useCallback(
    async (token: string) => {
      await createSessionWithToken(
        { token },
        {
          onSuccess: async (res) => {
            await processAuthorizationHeader(res.headers.authorization);
          },
        },
      );
    },
    [createSessionWithToken, processAuthorizationHeader],
  );

  const register = useCallback(
    async (name: string, email: string, companyName: string) => {
      await registerCompany({ name, email, companyName });
    },
    [registerCompany],
  );

  const startImpersonatingUser = useCallback(async () => {
    const target = prompt("I solemnly swear I am up to no good");

    if (target) {
      await toast.promise(
        impersonate.mutateAsync(
          { target },
          {
            onSuccess: async (res) => {
              await processAuthorizationHeader(res.headers.authorization);
              redirect("/");
            },
          },
        ),
        {
          pending: "Impersonating User...",
          success: "Switched to New User",
          error: {
            render: ({ data }) =>
              extractErrorMessage(
                data as Error,
                "Error while impersonating user.",
              ),
          },
        },
      );
    }
  }, [impersonate, redirect, processAuthorizationHeader]);

  const stopImpersonatingUser = useCallback(async () => {
    await stopImpersonating.mutateAsync(void null, {
      onSuccess: async (res) => {
        await processAuthorizationHeader(res.headers.authorization);
        redirect(
          "/",
          "Impersonation Stopped. You have been redirected to the home page.",
        );
      },
    });
  }, [stopImpersonating, redirect, processAuthorizationHeader]);

  const logout = useCallback(
    async ({ next }: { next?: string } = {}) => {
      setAuthToken(null);
      clearToken();

      queryClient.clear();
      queryClient.resetQueries();

      redirect(
        AppRoutes.login({ next }),
        "Thanks for coming. See you again soon.",
        "info",
      );
    },
    [queryClient, redirect],
  );

  const session = useQuery({
    queryFn: ({ signal }) => validateSession({ signal }),
    queryKey: ["session"],
    enabled: hasAuthenticated && isAuthenticated,
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (session.isError && isAuthError(session.error)) {
      logout();
    }
  }, [session.isError, session.error, logout]);

  // load token from storage if it is still valid
  useAsyncEffect(async (isActive) => {
    const token = getToken();

    if (token) {
      await session.refetch();

      if (isActive()) {
        setAuthToken(token);
      }
    } else {
      setAuthToken(null);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: isAuthenticated ? currentUser : undefined,
        trueUser,
        loading: isLoadingUser,
        isImpersonating,
        isAuthenticated,
        hasAuthenticated,
        authToken,
        processAuthorizationHeader,
        login,
        loginWithToken,
        logout,
        startImpersonatingUser,
        stopImpersonatingUser,
        register,
      }}
    >
      {hasAuthenticated ? children : <LoadingCentered />}
    </AuthContext.Provider>
  );
};
