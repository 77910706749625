import clsx from "clsx";

export const Swatch: React.FC<{ color: string; className?: string }> = ({
  color,
  className,
}) => {
  return (
    <div
      className={clsx(className, "swatch")}
      style={{ backgroundColor: color }}
    />
  );
};
