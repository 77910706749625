import React from "react";
import { LoadingCentered } from "./LoadingCentered";

export const LoadingGate: React.FC<{
  children: React.ReactNode;
  loadingNode?: React.ReactNode;
  loading?: boolean;
}> = ({ children, loading = false, loadingNode = <LoadingCentered /> }) => {
  return loading ? <>{loadingNode}</> : <>{children}</>;
};
