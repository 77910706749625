/* eslint-disable no-restricted-imports */
import clsx from "clsx";
import { ChevronDownIcon, ChevronRightIcon, LucideIcon } from "lucide-react";
import React, { useState } from "react";
import {
  Alert as BSAlert,
  AlertProps as BSAlertProps,
  Collapse,
  Stack,
} from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";
import { Button } from "./Button";
import { Icon } from "./icons/Icon";

export type AlertProps = BSAlertProps & {
  icon?: LucideIcon;
  iconColor?: Color;
  flush?: boolean;
};

export const Alert: React.FC<AlertProps> = ({
  children,
  variant,
  icon,
  className,
  iconColor,
  flush = false,
  ...props
}) => {
  return (
    <BSAlert
      variant={variant}
      {...props}
      className={clsx(
        "d-flex align-items-center gap-2",
        className,
        flush && "rounded-0 border-top-0 border-start-0 border-end-0",
      )}
    >
      {icon && <Icon icon={icon} color={iconColor ?? (variant as Color)} />}
      <div style={{ flex: 1 }}>{children}</div>
    </BSAlert>
  );
};

export const DetailedAlert: React.FC<
  { messages?: React.ReactNode[] } & AlertProps
> = ({ messages = [], children, variant = "secondary", ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <Alert variant={variant} {...rest}>
      <Stack direction="horizontal">
        {children}

        {messages && messages.length > 0 && (
          <Button
            variant={`outline-${variant}`}
            size="sm"
            className="ms-auto"
            EndIcon={open ? ChevronDownIcon : ChevronRightIcon}
            onClick={() => setOpen(!open)}
          >
            Show Details
          </Button>
        )}
      </Stack>

      {messages && messages.length > 0 && (
        <Collapse in={open}>
          <div>
            <ul
              className={`mt-3 pt-2 px-3 border-top border-${variant}-subtle list-unstyled`}
            >
              {messages.map((m, idx) => (
                <li className="mt-2" key={idx}>
                  {m}
                </li>
              ))}
            </ul>
          </div>
        </Collapse>
      )}
    </Alert>
  );
};
