import clsx from "clsx";
import { ChevronDown, ChevronUp } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { SimpleCardHeader } from "./SimpleCardHeader";

export const FloatingPanel: React.FC<
  PropsWithChildren & { className?: string; title: React.ReactNode }
> = ({ children, className, title }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Card
      className={clsx(
        "floating_panel",
        className,
        "border-primary border-bottom-0 shadow-lg",
      )}
    >
      <SimpleCardHeader
        title={title}
        onClick={() => setCollapsed(!collapsed)}
        icon={collapsed ? ChevronUp : ChevronDown}
        className="border-primary"
      />

      <Collapse in={!collapsed}>
        <div className="floating_panel__content">{children}</div>
      </Collapse>
    </Card>
  );
};
