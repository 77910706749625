import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { Account, Self } from "../../types";
import { api, fileFromResponse } from "../api";
import { ApiRoutes } from "../apiRoutes";
import { getToken } from "../auth";
import { useMutatorError } from "./apiError";
import { ApiResponse, SingleHook, UpdateParams } from "./types";

export type UpdateUserParams = UpdateParams<Self>;

type SetPasswordArgs = {
  password: string;
  invitation?: string;
  resetToken?: string;
};
export const setPassword = async (args: SetPasswordArgs) => {
  const resp = await api.put(
    ApiRoutes.myPassword(),
    { password: args.password },
    { params: { reset_token: args.resetToken, invitation: args.invitation } },
  );
  return resp;
};

type InvitationArgs = {
  invitation: string;
};
export const acceptInvitation = async (args: InvitationArgs) => {
  const r = await api.post(
    ApiRoutes.acceptInvitation(),
    {},
    { params: { invitation: args.invitation } },
  );
  return r.data;
};

export const declineInvitation = async (args: InvitationArgs) => {
  const r = await api.post(
    ApiRoutes.declineInvitation(),
    {},
    { params: { invitation: args.invitation } },
  );
  return r.data;
};

export const getCurrentUserWithResetToken = async (
  resetToken: string,
  conf: AxiosRequestConfig = {},
) => {
  const r = await api.get<SelfApiDetail>(ApiRoutes.me(), {
    params: { reset_token: resetToken },
    ...conf,
  });
  return r.data;
};

type ResendInvitationArgs = {
  email: string;
};
export const resendInvitation = async (args: ResendInvitationArgs) => {
  const r = await api.post(ApiRoutes.resendInvitation(), args);
  return r.data;
};

type CancelInvitationArgs = {
  email: string;
};
export const cancelInvitation = async (args: CancelInvitationArgs) => {
  const r = await api.post(ApiRoutes.cancelInvitation(), args);
  return r.data;
};

const getCurrentUser = async (conf: AxiosRequestConfig) => {
  const r = await api.get<SelfApiDetail>(ApiRoutes.me(), conf);

  return r.data;
};

const getInvitedUser = async (token: string, conf: AxiosRequestConfig) => {
  const r = await api.get<SelfApiDetail>(ApiRoutes.me(), {
    params: { invitation: token },
    ...conf,
  });
  return r.data;
};

const fetchUserFile = async (id: string, conf: AxiosRequestConfig) => {
  const resp = await api.get<Blob>(ApiRoutes.downloadUserFile({ id }), {
    headers: {
      Accept: "*/*",
    },
    responseType: "blob",
    timeout: 20000,
    ...conf,
  });

  return fileFromResponse(resp, "download", ".csv");
};

export type AccountApiDetail = ApiResponse<{ account: Account }>;
export type SelfApiDetail = ApiResponse<{ account: Self }>;

const updateCurrentUser = async ({ data }: UpdateUserParams) => {
  const resp = await api.patch<SelfApiDetail>(ApiRoutes.me(), data);

  return resp.data;
};

export const useUpdateCurrentUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: (data: SelfApiDetail) => {
      {
        queryClient.setQueryData(["user"], data);
      }
    },
  });
};

export const useSetPassword = (
  defaultError: string = "Dang! Something went wrong while we were setting your password.",
) => {
  const mutation = useMutation({ mutationFn: setPassword });
  const error = useMutatorError(mutation, defaultError);
  return { setPassword: mutation, error };
};

export const useAcceptInvitation = (
  defaultError: string = "Uh-oh! Something went wrong while we were accepting the invitation.",
) => {
  const mutation = useMutation({ mutationFn: acceptInvitation });
  const error = useMutatorError(mutation, defaultError);
  return { acceptInvitation: mutation, error };
};

export const useDeclineInvitation = (
  defaultError: string = "Uh-oh! Something went wrong while we were declining the invitation.",
) => {
  const mutation = useMutation({ mutationFn: declineInvitation });
  const error = useMutatorError(mutation, defaultError);
  return { declineInvitation: mutation, error };
};

export const useResendInvitation = (
  defaultError: string = "Something went wrong while we were resending the invitation.",
) => {
  const mutation = useMutation({ mutationFn: resendInvitation });
  const error = useMutatorError(mutation, defaultError);
  return { resendInvitation: mutation, error };
};

export const useCancelInvitation = (
  defaultError: string = "Something went wrong while we were canceling the invitation.",
) => {
  const mutation = useMutation({ mutationFn: cancelInvitation });
  const error = useMutatorError(mutation, defaultError);
  return { cancelInvitation: mutation, error };
};

export const useInvitedUser = (
  invitationToken: string | undefined = "",
): Omit<SingleHook<Account, AccountApiDetail>, "destroy" | "update"> => {
  const query = useQuery({
    queryFn: ({ signal }) => getInvitedUser(invitationToken, { signal }),
    enabled: Boolean(invitationToken),
    queryKey: ["user", "invited", invitationToken],
  });

  return { data: query.data, query };
};

export const useDownloadUserFile = (id: string) => {
  const query = useQuery({
    queryFn: ({ signal }) => fetchUserFile(id, { signal }),
    queryKey: ["user", "user_files", id],
    enabled: false,
    gcTime: 0,
  });

  useEffect(() => {
    if (query.data) {
      saveAs(query.data);
    }
  }, [query.data]);

  return query;
};

export const useCurrentUser = (
  options: Partial<UseQueryOptions<SelfApiDetail>> = {},
): Omit<SingleHook<Self, SelfApiDetail, UpdateUserParams>, "destroy"> => {
  const token = getToken();

  const update = useUpdateCurrentUser();

  const query = useQuery({
    queryFn: ({ signal }) => getCurrentUser({ signal }),
    enabled: Boolean(token),
    queryKey: ["user"],
    ...options,
  });

  return { data: query.data, query, update };
};
