import React, { useCallback, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useStateParams } from "../../hooks/useStateParams";

type ControlledModalControlProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
};

type QueryParamModalProps = {
  queryParam: string;
  trigger?: React.ReactElement;
  onOpened?: () => void;
  onClosed?: (success: boolean) => void;
};

type TriggerModalControlProps = {
  trigger: React.ReactElement;
  onOpened?: () => void;
  onClosed?: (success: boolean) => void;
};

export type ModalControlProps =
  | ControlledModalControlProps
  | QueryParamModalProps
  | TriggerModalControlProps;

type CoreModalProps = {
  title: React.ReactNode;
  actions?: React.ReactNode;
  dismissable: boolean;

  children: (_args: { handleClose: (success: boolean) => void }) => JSX.Element;
} & Omit<ModalProps, "children">;

export type BaseModalProps = CoreModalProps & ModalControlProps;

const ControlledModal = React.forwardRef<
  HTMLElement,
  CoreModalProps & ControlledModalControlProps
>(
  (
    {
      title,
      actions,
      children,
      dismissable = false,
      open,
      handleClose,
      handleOpen: _,
      onClosed: _onClosed,
      ...rest
    },
    ref,
  ) => {
    const wrappedHandleClose = (success: boolean | Event) => {
      const isSuccessEvent = typeof success === "object";
      const isSuccess = !isSuccessEvent && Boolean(success);
      handleClose(isSuccess);
    };

    return (
      <Modal
        ref={ref}
        show={open}
        onHide={dismissable ? () => handleClose(false) : undefined}
        enforceFocus={false}
        {...rest}
      >
        {title && (
          <Modal.Header
            closeButton={dismissable}
            className="d-flex flex-row align-items-center"
          >
            {title}
            {actions && (
              <aside className="ms-auto text-end flex-grow me-3">
                {actions}
              </aside>
            )}
          </Modal.Header>
        )}

        {children({ handleClose: wrappedHandleClose })}
      </Modal>
    );
  },
);
ControlledModal.displayName = "ControlledModal";

const TriggerModal = React.forwardRef<
  HTMLElement,
  CoreModalProps & TriggerModalControlProps
>((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = (success: boolean) => {
    setOpen(false);
    props.onClosed?.(success);
  };

  const triggerComponent = React.cloneElement(props.trigger, {
    onClick: handleOpen,
  });

  return (
    <>
      {triggerComponent}
      <ControlledModal
        ref={ref}
        {...props}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  );
});
TriggerModal.displayName = "TriggerModal";

const QueryParamModal = React.forwardRef<HTMLElement, CoreModalProps>(
  ({ queryParam, ...props }, ref) => {
    const [open, setOpen] = useStateParams(queryParam, false);

    const handleOpen = useCallback(() => setOpen(true), [setOpen]);

    const handleClose = useCallback(
      (success: boolean) => {
        setOpen(false);
        props.onClosed?.(success);
      },
      [setOpen, props.onClosed],
    );

    const triggerComponent = props.trigger
      ? React.cloneElement(props.trigger, {
          onClick: handleOpen,
        })
      : null;

    return (
      <>
        {triggerComponent}
        <ControlledModal
          ref={ref}
          {...props}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </>
    );
  },
);
QueryParamModal.displayName = "QueryParamModal";

export const BaseModal = React.forwardRef<HTMLElement, BaseModalProps>(
  (props, ref) => {
    if (props.queryParam) {
      return <QueryParamModal ref={ref} {...props} />;
    }

    if (props.trigger) {
      return <TriggerModal ref={ref} {...props} />;
    }

    return <ControlledModal ref={ref} {...props} />;
  },
);
BaseModal.displayName = "BaseModal";
