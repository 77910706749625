import { Account, AccountSummary, Org, OrgSummary } from "src/types";

export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "admin";
export const ROLE_MEMBER = "member";
export const ROLE_CONTACT = "contact";
export const ROLE_NO_ROLE = "no_role";

export const ACCOUNT_ROLES = Object.freeze([
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_MEMBER,
  ROLE_NO_ROLE,
]);

export const ADMIN_ROLES = Object.freeze([ROLE_OWNER, ROLE_ADMIN]);

export const isAdminAccount = (account: Account | undefined) =>
  account && ADMIN_ROLES.includes(account.role);

export const isContactAccount = (
  account: AccountSummary | Account | undefined,
) => account && account.role === "contact";

export const isRestrictedAccount = (account: Account | undefined) =>
  account && account.role === ROLE_NO_ROLE;

export const isRestrictedOrg = (org: Org | OrgSummary | undefined) =>
  org?.status !== "active";

export const isDeactivatedOrg = (org: Org | OrgSummary | undefined) =>
  org?.status === "inactive";

export const isPendingOrg = (org: Org | OrgSummary | undefined) =>
  org?.status === "pending";

export const isGlobalAdmin = (user: Account | undefined) =>
  user && user.globalRole == ROLE_ADMIN;
