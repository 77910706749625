import { UseMutationResult } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { castArray, get, has } from "lodash-es";
import { useMemo } from "react";

export const useMutatorError = (
  mutator: UseMutationResult<any, any, any, any>,
  def: string,
): string | undefined => {
  return useMemo(() => {
    if (mutator.error) {
      return extractErrorMessage(mutator.error, def);
    }
  }, [mutator.error, def]);
};

export const extractErrorMessage = (
  error: Error | AxiosError | unknown,
  fallback: string = "Something went wrong",
): string => {
  if (axios.isAxiosError(error)) {
    if (has(error, "response.data.errors")) {
      const errors = get(error, "response.data.errors");

      return castArray(errors).join(", ");
    }
    if (has(error, "response.data.error")) {
      return get(error, "response.data.error");
    }
  } else if (has(error, "message")) {
    return get(error, "message") as unknown as string;
  }

  return fallback;
};
