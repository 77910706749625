import clsx from "clsx";
import { isNumber } from "lodash-es";
import { LucideIcon } from "lucide-react";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Color } from "react-bootstrap/esm/types";

export type IconProps = {
  icon: LucideIcon;
  className?: string;
  color?: Color;
  size?: "sm" | "lg" | "1" | "2" | "3" | "4" | "5" | "6" | number;
  style?: React.CSSProperties;
};

export const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  ({ icon: LucideIcon, color, className, style, size }, ref) => {
    const hasNumericSize = size && isNumber(size);
    return LucideIcon ? (
      <span
        ref={ref}
        style={style}
        className={clsx(
          className,
          "icon",
          color && `text-${color}`,
          size && !hasNumericSize && `fs-${size}`,
        )}
      >
        <LucideIcon
          size={hasNumericSize ? size : undefined}
          style={{
            height: hasNumericSize ? size : undefined,
            width: hasNumericSize ? size : undefined,
          }}
        />
      </span>
    ) : null;
  },
);

Icon.displayName = "Icon";

export const TooltipIcon = React.forwardRef<
  HTMLDivElement,
  IconProps & { title: React.ReactNode }
>(({ title, ...props }, ref) => {
  const icon = <Icon ref={ref} {...props} />;

  return title ? (
    <OverlayTrigger
      overlay={<Tooltip>{title}</Tooltip>}
      trigger={["hover", "focus"]}
      placement="bottom-end"
    >
      <span style={{ lineHeight: 0 }}>{icon}</span>
    </OverlayTrigger>
  ) : (
    icon
  );
});

TooltipIcon.displayName = "Icon";
