import { noop } from "lodash-es";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import {
  clearFlashMessages,
  FlashMessageType,
  setFlashMessage,
} from "src/components/FlashMessagesContainer";

export const useRedirect: () => (
  path: string,
  message?: string,
  messageType?: FlashMessageType,
) => Promise<void> = () => {
  const router = useRouter();

  return useCallback(
    (
      path: string,
      message?: string,
      messageType: FlashMessageType = "info",
    ) => {
      clearFlashMessages();
      if (message) {
        setFlashMessage(message, messageType);
      }

      router.push(path);

      return new Promise(noop);
    },
    [router],
  );
};

// This returns a promise that will never resolve, so you can await this redirect, and it will wait until the page loads
export const redirect = (
  path: string,
  message?: string,
  messageType: FlashMessageType = "info",
): Promise<void> => {
  clearFlashMessages();
  if (message) {
    setFlashMessage(message, messageType);
  }

  window.location.replace(path);

  return new Promise(noop);
};
