import React from "react";
import { Spinner } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

export const Loading: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    size?: "xs" | "sm" | "md";
    variant?: Variant;
  }
> = ({ size, variant = "secondary", ...props }) => {
  return (
    <Spinner
      as="span"
      animation="border"
      role="status"
      aria-hidden="false"
      variant={variant}
      size={size as any} // we allow "xs" as a size, but isn't recognized by the type
      {...props}
    />
  );
};
