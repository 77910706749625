import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { mergeRefs } from "../utils/mergeRefs";

export const useMeasureWidth = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
      setHeight(entries[0].contentRect.height);
    });

    if (currentRef) {
      observer.observe(currentRef);

      return () => {
        currentRef && observer.unobserve(currentRef);
      };
    } else {
      setWidth(undefined);
      setHeight(undefined);
    }
  }, [ref.current]);

  return { ref, width, height };
};

export const useMeasureNaturalSize = () => {
  const ref = useRef<HTMLImageElement>(null);

  const [naturalWidth, setNaturalWidth] = useState<number | undefined>(
    undefined,
  );

  const [naturalHeight, setNaturalHeight] = useState<number | undefined>(
    undefined,
  );

  const updateNaturalSize = useCallback(() => {
    setNaturalWidth(ref.current?.naturalWidth);
    setNaturalHeight(ref.current?.naturalHeight);
  }, [ref.current]);

  useEffect(() => {
    updateNaturalSize();
  }, [updateNaturalSize, ref.current?.complete]);

  return { ref, naturalHeight, naturalWidth };
};

export const useImageMeasures = () => {
  const {
    ref: naturalSizeRef,
    naturalWidth,
    naturalHeight,
  } = useMeasureNaturalSize();

  const {
    ref: currentSizeRef,
    width: clientWidth,
    height: clientHeight,
  } = useMeasureWidth<HTMLImageElement>();

  const clientWidthScaleFactor = useMemo(
    () => (clientWidth && naturalWidth ? clientWidth / naturalWidth : 0),
    [clientWidth, naturalWidth],
  );

  const clientHeightScaleFactor = useMemo(
    () => (clientHeight && naturalHeight ? clientHeight / naturalHeight : 0),
    [clientHeight, naturalHeight],
  );

  return {
    ref: mergeRefs(naturalSizeRef, currentSizeRef),
    naturalWidth,
    naturalHeight,
    clientWidth,
    clientHeight,
    clientWidthScaleFactor,
    clientHeightScaleFactor,
  };
};
