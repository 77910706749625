import React from "react";
import { Card, CardProps } from "react-bootstrap";
import { LoadingCentered } from "./LoadingCentered";

export const LoadingCard: React.FC<CardProps> = (props) => {
  return (
    <Card {...props}>
      <LoadingCentered />
    </Card>
  );
};
