import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React from "react";
import { Card, Stack } from "react-bootstrap";
import { CardHeaderProps } from "react-bootstrap/esm/CardHeader";
import { Loading } from "./Loading";
import { Icon } from "./icons/Icon";

type Props = {
  title: React.ReactNode;
  actions?: React.ReactNode;
  dense?: boolean;
  icon?: LucideIcon;
  loading?: boolean;
  disabled?: boolean;
} & Omit<CardHeaderProps, "children" | "title">;

export const SimpleCardHeader = React.forwardRef<HTMLDivElement, Props>(
  (
    { title, icon, loading, actions, className, dense, disabled, ...rest },
    ref,
  ) => {
    return (
      <Card.Header
        {...rest}
        ref={ref}
        className={clsx(
          className,
          "simple_card_header",
          dense && "simple_card_header--dense",
          Boolean(rest.onClick) && "card-action",
          disabled && "disabled",
        )}
      >
        <Stack direction="horizontal">
          <Card.Title className="me-auto">{title}</Card.Title>

          {actions}

          {loading ? (
            <Loading size="xs" className="text-muted" />
          ) : (
            <>{icon && <Icon icon={icon} className="text-muted" />}</>
          )}
        </Stack>
      </Card.Header>
    );
  },
);

SimpleCardHeader.displayName = "SimpleCardHeader";
