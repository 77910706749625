import { templatizeRouteObj } from "./routing";

const routes = {
  createOrg: "/api/orgs",
  login: "/api/login",
  loginWithToken: "/api/magic_login",
  createMagicToken: "/api/magic",
  validateMagicToken: "/api/magic/:token",
  refreshMagicToken: "/api/magic_refresh",
  forgotPassword: "/api/forgot",
  validateForgotPassword: "/api/password-reset/:token",
  impersonateUser: "/api/impersonate",

  acceptInvitation: "/api/users/invitation/accept",
  declineInvitation: "/api/users/invitation/decline",
  resendInvitation: "/api/users/invitation/resend",
  cancelInvitation: "/api/users/invitation/cancel",

  campaigns: "/api/campaigns",
  campaign: "/api/campaigns/:id",
  campaignSampleCsv: "/api/campaigns/:id/sample_csv",

  campaignPackets: "/api/campaigns/:id/packets",
  campaignBulkSendForSignature: "/api/campaigns/:id/packets/send_signature",
  campaignPacket: "/api/campaigns/:id/packets/:campaignPacketId",
  campaignBulkSign: "/api/campaigns/:id/packets/sign",
  campaignStart: "/api/campaigns/:id/start",
  campaignClose: "/api/campaigns/:id/close",
  campaignExport: "/api/campaigns/:id/export",

  contacts: "/api/contacts",
  contact: "/api/contacts/:id",
  moveContact: "/api/contacts/:id/move",
  archiveContact: "/api/contacts/:id/archive",
  unarchiveContact: "/api/contacts/:id/unarchive",
  importContacts: "/api/contacts/import",
  sampleImportCsv: "/api/contacts/sample_csv",
  companyContacts: "/api/companies/:companyId/contacts",

  contactDocuments: "/api/contacts/:contactId/documents",
  companyDocuments: "/api/companies/:companyId/documents",

  companies: "/api/companies",
  company: "/api/companies/:id",
  archiveCompany: "/api/companies/:id/archive",
  unarchiveCompany: "/api/companies/:id/unarchive",

  packets: "/api/packets",
  packet: "/api/packets/:id",
  packetVerions: "/api/packets/:id/versions",
  executePacket: "/api/packets/:id/execute",
  packetTemplates: "/api/packets/templates",
  combinePackets: "/api/packets/:id/combine/:otherId",
  archivePacket: "/api/packets/:id/archive",
  unarchivePacket: "/api/packets/:id/unarchive",
  removeDocumentFromPacket: "/api/packets/:id/documents/:documentId",

  documents: "/api/documents",
  documentsRecommendedReview: "/api/documents/recommended/review",
  document: "/api/documents/:id",
  documentVersion: "/api/documents/:id/version",
  archiveDocument: "/api/documents/:id/archive",
  unarchiveDocument: "/api/documents/:id/unarchive",
  downloadDocument: "/api/documents/:id/download.:ext",
  previewDocumentPdf: "/api/documents/:id/pdf",
  copyDocument: "/api/documents/:id/copy",
  lockDocument: "/api/documents/:id/lock",
  documentTags: "/api/documents/:id/tags",
  documentTag: "/api/documents/:id/tags/:tagId",
  documentAttachments: "/api/documents/:documentId/attachments",
  documentPagePreview: "/api/documents/:documentId/pages/:pageNum",
  removeDraftWatermark:
    "/api/documents/:documentId/versions/:versionId/draft_watermark",
  documentReviews: "/api/document_reviews",
  documentReview: "/api/document_reviews/:id",
  documentReviewResendInvitation: "/api/document_reviews/:id/invitation/resend",
  signDocumentReview: "/api/document_reviews/:id/sign",
  documentReviewReviewers: "/api/document_reviews/:id/reviewers",
  closeDocumentReview: "/api/document_reviews/:id/actions/close",
  reopenDocumentReview: "/api/document_reviews/:id/actions/reopen",

  approveDocumentReviewWithToken: "/api/document_reviews/reactions/approve",
  approveDocumentReview: `/api/document_reviews/:reviewId/reactions/approve`,
  requestChangesDocumentReview: `/api/document_reviews/:reviewId/reactions/request_changes`,
  clearReaction: `/api/document_reviews/:reviewId/reactions/clear_reaction`,
  declineReaction: `/api/document_reviews/:reviewId/reactions/decline`,
  documentReviewComments: "/api/document_reviews/:reviewId/comments",
  documentReviewActions: "/api/document_reviews/:reviewId/actions",
  documentReviewTasks: "/api/document_reviews/:reviewId/tasks",
  documentReviewTask: "/api/document_reviews/:reviewId/tasks/:taskId",

  unifiedDiff: "/api/documents/diff/:startId..:endId",
  splitDiff: "/api/documents/diff/:startId..:endId/split",

  documentReviewRevisions: "/api/document_reviews/:reviewId/versions",
  documentReviewRevision: "/api/document_reviews/:reviewId/versions/:revision",
  comment: "/api/comments/:commentId",
  replyToComment: "/api/comments/:commentId/reply",
  resolveComment: "/api/comments/:commentId/resolve",
  unresolveComment: "/api/comments/:commentId/unresolve",

  tags: "/api/document_tags",
  tag: "/api/document_tags/:id",

  features: "/api/features",

  org: "/api/org",
  orgMemberships: "/api/org/memberships",
  orgMembership: "/api/org/memberships/:id",

  themes: "/api/themes",
  themePreviewPdf: "/api/themes/:id/preview/pdf",
  fonts: "/api/themes/fonts",

  translations: "/api/translations",

  me: "/api/me",
  downloadUserFile: "/api/me/files/:id",
  myPassword: "/api/me/password",
};

export const ApiRoutes = templatizeRouteObj(routes);
