import clsx from "clsx";
import React from "react";

type ChipProps = (
  | React.ComponentPropsWithRef<"span">
  | React.ComponentPropsWithRef<"button">
) & {
  button?: boolean;
  color?: "info" | "secondary" | "primary" | "gray" | "green" | "orange";
};

export const Chip = React.forwardRef<HTMLElement, ChipProps>(
  ({ children, color = "info", className, button = false, ...rest }, ref) =>
    button ? (
      <button
        type="button"
        {...rest}
        ref={ref as any}
        className={clsx(`chip chip--${color}`, className)}
      >
        {children}
      </button>
    ) : (
      <span
        {...rest}
        ref={ref}
        className={clsx(`chip chip--${color}`, className)}
      >
        {children}
      </span>
    ),
);

Chip.displayName = "Chip";
