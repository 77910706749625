/* eslint-disable no-restricted-imports */
import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React from "react";
import {
  Button as BSButton,
  ButtonProps as BSButtonProps,
} from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";
import { DisabledTooltip } from "./DisabledTooltip";
import { Loading } from "./Loading";
import { Icon } from "./icons/Icon";

export type ButtonProps = BSButtonProps & {
  StartIcon?: LucideIcon;
  EndIcon?: LucideIcon;
  iconOnly?: boolean;
  iconColor?: Color;
  loadingLabel?: React.ReactNode;
  tooltip?: string;
  disabledTooltip?: string;
  block?: boolean;
  showLoadingLabel?: boolean;
  loading?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      StartIcon,
      EndIcon,
      loading,
      disabled,
      disabledTooltip,
      tooltip,
      children,
      iconOnly = false,
      iconColor,
      loadingLabel = "Loading...",
      showLoadingLabel = false,
      className,
      block = false,
      ...rest
    },
    ref,
  ) => {
    const hasLabel = Boolean(!iconOnly && children);

    const button = (
      <BSButton
        className={clsx(
          block && "btn-block",
          {
            "w-100": (tooltip || (disabled && disabledTooltip)) && block,
          },
          className,
        )}
        {...rest}
        disabled={loading || disabled}
        ref={ref}
      >
        {loading ? (
          <>
            <Loading
              size={(rest.size as any) === "xs" ? "xs" : ("sm" as any)}
            />
            {showLoadingLabel && loadingLabel && (
              <span className="ms-1">{loadingLabel}</span>
            )}
          </>
        ) : (
          <>
            {StartIcon && (
              <Icon
                color={iconColor}
                icon={StartIcon}
                className={clsx(hasLabel && "me-1")}
              />
            )}

            {hasLabel && <span>{children}</span>}

            {EndIcon && (
              <Icon
                color={iconColor}
                icon={EndIcon}
                className={clsx(hasLabel && "ms-1")}
              />
            )}
          </>
        )}
      </BSButton>
    );

    return (
      <DisabledTooltip
        disabled={disabled}
        tooltip={tooltip}
        disabledTooltip={disabledTooltip}
        block={block}
      >
        {button}
      </DisabledTooltip>
    );
  },
);

Button.displayName = "Button";
