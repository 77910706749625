import React, { useState } from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";

type Props = {
  trigger: React.ReactElement;
  children: (_args: { handleClose: () => void }) => JSX.Element;
} & Omit<DropdownProps, "children">;

export const BaseDropdown: React.FC<Props> = ({
  trigger,
  children,
  className,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Dropdown
      show={open}
      autoClose
      onToggle={() => setOpen((o) => !o)}
      {...rest}
    >
      <Dropdown.Toggle
        className={className}
        as={trigger.type}
        {...trigger.props}
      />
      {children({ handleClose })}
    </Dropdown>
  );
};
