import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React from "react";
import { Card, CardFooterProps } from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";
import { IconText } from "./icons/IconText";

type AlertCardFooterProps = CardFooterProps & {
  icon?: LucideIcon;
  variant?: Color;
};

export const AlertCardFooter: React.FC<AlertCardFooterProps> = ({
  icon,
  className,
  variant,
  children,
}) => {
  return (
    <Card.Footer
      className={clsx(
        `small border-${variant} bg-${variant}-subtle`,
        className,
      )}
    >
      {icon ? (
        <IconText icon={icon} iconColor={variant}>
          {children}
        </IconText>
      ) : (
        children
      )}
    </Card.Footer>
  );
};
