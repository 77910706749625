import clsx from "clsx";
import React, { useState } from "react";
import {
  OverlayTrigger,
  OverlayTriggerProps,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";

export type BaseOverlayProps = {
  trigger: React.ReactElement;
  children: (_args: { handleClose: () => void }) => JSX.Element;
  as?: React.ElementType<TooltipProps>;
} & Omit<OverlayTriggerProps, "overlay" | "trigger" | "children">;

const DefaultOverlayComponent = React.forwardRef<HTMLDivElement, TooltipProps>(
  (props, ref) => {
    return (
      <Tooltip
        {...props}
        className={clsx("clean-tooltip", props.className)}
        ref={ref}
      />
    );
  },
);

export const BaseOverlay: React.FC<BaseOverlayProps> = ({
  trigger,
  children,
  placement = "auto",
  rootClose = true,
  as: Component = DefaultOverlayComponent,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <OverlayTrigger
      rootClose={rootClose}
      placement={placement}
      show={open}
      trigger="click"
      onToggle={setOpen}
      transition={false}
      {...rest}
      overlay={
        <Component style={{ zIndex: 1070 }}>
          {children({ handleClose })}
        </Component>
      }
    >
      {trigger}
    </OverlayTrigger>
  );
};
