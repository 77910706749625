import {
  format,
  formatDistanceStrict,
  formatISO,
  isDate,
  isValid,
  parseISO,
} from "date-fns";

export type ZonedDateTimeString = string;
export type PlainDateString = string;

export const DATE_TIME_FORMAT_STRING = "MM/dd/yyyy h:mm a";
export const DATE_FORMAT_STRING = "MM/dd/yyyy";
export const HUMAN_DATE_FORMAT_STRING = "MMM d, yyyy";
export const HUMAN_TIME_FORMAT_STRING = "MMM d, yyyy";

export const formatDateTime = (date: Date | ZonedDateTimeString): string => {
  return formatDate(date, DATE_TIME_FORMAT_STRING);
};

export const formatDate = (
  date: Date | ZonedDateTimeString,
  fmt: string = DATE_FORMAT_STRING,
): string => {
  if (!date) {
    return "";
  }

  return format(parseDate(date), fmt);
};

export const formatSince = (
  date: Date | ZonedDateTimeString,
  {
    startDate,
    addSuffix,
  }: {
    startDate?: Date;
    addSuffix: boolean;
  } = { addSuffix: false },
) =>
  formatDistanceStrict(parseDate(date), startDate ?? Date.now(), { addSuffix });

export const formatDateIso = (date: Date | ZonedDateTimeString) =>
  formatISO(parseDate(date));

export const parseDate = (date: Date | ZonedDateTimeString): Date =>
  isDate(date) ? new Date(date) : parseDateString(date as ZonedDateTimeString);

export const parseDateStrict = (
  date: Date | ZonedDateTimeString,
): Date | null => {
  const init = parseDate(date);
  if (!isValid(init)) {
    return null;
  }
  return init;
};

export const parseDateString = (dateString: ZonedDateTimeString) =>
  parseISO(dateString);
