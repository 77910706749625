import clsx from "clsx";
import { LucideIcon } from "lucide-react";
import React from "react";
import { Color } from "react-bootstrap/esm/types";
import { TooltipIcon } from "./Icon";

export const IconText: React.FC<{
  icon?: LucideIcon;
  title?: string;
  children: React.ReactNode;
  className?: string;
  iconColor?: Color;
  gap?: number;
  as?: React.ElementType<{ children?: React.ReactNode; className?: string }>;
}> = ({
  icon,
  title,
  children,
  iconColor = "muted",
  as: Comp = "span",
  className,
  gap = 2,
}) => (
  <Comp
    className={clsx(className, `d-inline-flex align-items-center gap-${gap}`)}
  >
    {icon && <TooltipIcon title={title} color={iconColor} icon={icon} />}{" "}
    {children}
  </Comp>
);
