import clsx from "clsx";
import React from "react";
import { Loading } from "./Loading";

export const LoadingCentered: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className, style, ...props }) => {
  return (
    <div
      {...props}
      className={clsx(
        "d-flex flex-column align-items-center justify-content-center",
        className,
      )}
      style={{ ...style, height: style?.height ?? 120 }}
    >
      <Loading />
    </div>
  );
};
