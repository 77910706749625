import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { api } from "../api";
import { ApiRoutes } from "../apiRoutes";

type RegisterCompanyParams = {
  email: string;
  name: string;
  companyName: string;
};
const registerCompany = (params: RegisterCompanyParams) =>
  api.post(ApiRoutes.createOrg(), {
    name: params.companyName,
    owner_name: params.name,
    owner_email: params.email,
  });

type CreateSessionParams = { email: string; password: string };
const createSession = ({ email, password }: CreateSessionParams) =>
  api.post(ApiRoutes.login(), { email, password });

type CreateSessionWithTokenParams = { token: string };
const createSessionWithToken = ({ token }: CreateSessionWithTokenParams) =>
  api.post(ApiRoutes.loginWithToken(), { token });

export const validateMagicToken = ({ token }: CreateSessionWithTokenParams) =>
  api.get(ApiRoutes.validateMagicToken({ token })).then((r) => r.data);

type RefreshMagicTokenParams = { token: string };
const refreshMagicToken = ({ token }: RefreshMagicTokenParams) =>
  api.post(ApiRoutes.refreshMagicToken(), { token });

type CreateMagicTokenParams = { email: string };
const createMagicToken = ({ email }: CreateMagicTokenParams) =>
  api.post(ApiRoutes.createMagicToken(), { email });

type ForgotPasswordParams = { email: string };
const forgotPassword = ({ email }: ForgotPasswordParams) =>
  api.post(ApiRoutes.forgotPassword(), { email });

type ImpersonateUserParams = { target: string };
const impersonateUser = ({ target }: ImpersonateUserParams) =>
  api.post(ApiRoutes.impersonateUser(), { target });
const stopImpersonatingUser = () => api.delete(ApiRoutes.impersonateUser());

export const useImpersonateUser = () => {
  const queryClient = useQueryClient();

  const impersonate = useMutation({
    mutationFn: impersonateUser,
    onSettled: () => queryClient.invalidateQueries(),
  });

  const stopImpersonating = useMutation({
    mutationFn: stopImpersonatingUser,
    onSettled: () => queryClient.invalidateQueries(),
  });

  return { impersonate, stopImpersonating };
};

export const useCreateSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSession,
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["user"],
      }),
  });
};

export const useCreateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: registerCompany,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

export const useCreateSessionWithToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSessionWithToken,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["user"],
      }),
  });
};

export const useCreateMagicToken = () => {
  return useMutation({ mutationFn: createMagicToken });
};

export const useRefreshMagicToken = () => {
  return useMutation({ mutationFn: refreshMagicToken });
};

export const useForgotPassword = () => {
  return useMutation({ mutationFn: forgotPassword });
};

export const validateForgotPasswordToken = async (requestToken: string) =>
  await api.get(ApiRoutes.validateForgotPassword({ token: requestToken }));

export const validateSession = async (conf: AxiosRequestConfig) =>
  await api.get("/api/status", conf);

export const useValidateMagicToken = (args: CreateSessionWithTokenParams) => {
  const query = useQuery({
    queryKey: ["magic_token", args.token],
    queryFn: () => validateMagicToken(args),
  });

  return { data: query.data, query };
};
